import React from 'react';
import './Button.css';


interface ButtonProps {
    type: string;
    name: string;
    width?: string;
    link?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
const LOGIN_TYPE = 'login';

const Button: React.FC<ButtonProps> = ({ type, name, width, link = '', onClick }) => {
    const buttonType = type === LOGIN_TYPE ? 'login-type' : 'register-type';

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (type == LOGIN_TYPE) {
            event.preventDefault();
            if (link !== '') {
                window.location.href = link;
            }
            if (onClick) {
                onClick(event);
            }
        }
        else {
            if (link !== '') {
                window.location.href = link;
            }
        }
    };

    return (
        <div className="container-button">
            <button className={buttonType} type="button" onClick={handleClick}>
                {name}
            </button>
        </div>
    );
};

export default Button;