import React, { useState } from 'react';
import Alert from 'Components/Alert';
import Input from 'Components/Input';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import Button from 'Components/Button';
import Spinner from 'Components/Spinner'
import { Navigate } from 'react-router-dom';
import axios from '../../redux/features/axios-client';

const Reset: React.FC = () => {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [toLogin, setToLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [visible, setVisible] = useState(false);

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handlePasswordConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(event.target.value);
    };

    const handleCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(event.target.value);
    };

    const sendData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/change-password`, {
                current_password: currentPassword,
                new_password: password,
                confirm_password: passwordConfirmation,
            });
            if (response.status == 200) {
                setVisible(true);
                setSuccess('Contraseña cambiada con éxito.')
                setTimeout(() => {
                    setToLogin(true);
                }, 3000);
            }
        } catch (error: any) {
            setVisible(true);
            setError(error.response.data.error)
        }
        setIsLoading(false);
    }

    const handleSubmit = async (e: any) => {
        if (!currentPassword.trim()) {
            setError('Ingresa tu contraseña actual');
            setVisible(true);
            return
        } else if (password.length < 8) {
            setError('La contraseña actual debe tener al menos 8 caracteres');
            setVisible(true);
            return
        }
        if (!password.trim()) {
            setError('Completa todo los campos');
            setVisible(true);
            return
        } else if (password.length < 8) {
            setError('La contraseña debe tener al menos 8 caracteres');
            setVisible(true);
            return
        }

        if (!passwordConfirmation.trim()) {
            setError('La confirmación de contraseña es requerida');
            setVisible(true);
            return
        } else if (password !== passwordConfirmation) {
            setError('Las contraseñas no coinciden');
            setVisible(true);
            return
        }
        sendData();
    }

    return (
        <div id="container">
            {toLogin && (
                <Navigate to="/home" replace={true} />
            )}
            {isLoading && <Spinner />}
            {error && <Alert isVisible={visible} type='error' setVisible={setVisible} text={error} />}
            {success && <Alert isVisible={visible} type='success' setVisible={setVisible} text={success} />}
            <Header type="back-arrow" />
            <div className="main">
                <div className="register-container">
                    <form className="register-form">
                        <p className="create-text">CAMBIAR CONTRASEÑA</p>
                        <Input
                            name="current_password"
                            id="current_password"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            type="password"
                            onChange={handleCurrentPassword}
                            label="Contraseña actual" />
                        <Input
                            name="password"
                            id="password"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            type="password"
                            info="Mínimo 8 caracteres"
                            onChange={handlePassword}
                            label="Contraseña nueva" />
                        <Input
                            name="password_confirmation"
                            id="password_confirmation"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            type="password"
                            info="Mínimo 8 caracteres"
                            onChange={handlePasswordConfirmation}
                            label="Repetir contraseña nueva" />
                        <Button type="login" name="CAMBIAR CONTRASEÑA" onClick={handleSubmit} />
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Reset;