import React from 'react';
import Button from 'Components/Button';
import './Banner.css';


interface Banner {
  title: string;
  description: string;
  extend: string;
  link: string;
}

const Banner: React.FC<Banner> = ({ title, description, extend, link }) => {
  return (
    <div className='container-banner'>
      <p className='description-title'>
        {title}
      </p>
      <p className='description-text'>
        {description}
      </p>
      <p className='extend-text'>
        {extend}
      </p>
      <Button name="Sacar un turno" link={link} type="login" />
    </div>
  );
};

export default Banner;