import React, { useState, useEffect, useRef } from 'react';
import Button from 'Components/Button';
import Header from 'Components/Header';
import Input from 'Components/Input';
import BirthdayInput from 'Components/BirthdayInput';
import Select from 'Components/Select';
import Footer from 'Components/Footer';
import Spinner from 'Components/Spinner';
import { fetchToken } from "../../redux/features/getToken";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { registerSuccess } from "../../redux/features/loginState";
import { userRegister } from "../../redux/features/registerState";
import Alert from 'Components/Alert';
import './SignUp.css';
import {AsyncThunkPayloadCreatorReturnValue} from "@reduxjs/toolkit";
import {useGetCountriesQuery} from "../../redux/features/getCountries";
import {useGetProvincesQuery} from "../../redux/features/getProvinces";
import {useGetGendersQuery} from "../../redux/features/getGenders";
import {useGetDocumentTypesQuery} from "../../redux/features/getDocumentTypes";
import {Country} from "../../Types/Country";
import {Gender} from "../../Types/Gender";
import {Province} from "../../Types/Province";
import {DocumentType} from "../../Types/DocumentType";

const SignUp: React.FC = () => {

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    birthday: '',
    birthday_day: 0,
    birthday_month: 0,
    birthday_year: 0,
    document: '',
    document_type_id: 0,
    country_id: 0,
    province_id: 0,
    gender_id: 0,
    email: '',
    password: '',
    password_confirmation: ''
  });

  const dispatch = useAppDispatch();
  const register = useAppSelector((state) => state.userRegister);
  const { data: countries, error: countriesError, isLoading: isLoadingCountries } = useGetCountriesQuery();
  const { data: provinces, error: provincesError, isLoading: isLoadingProvinces } = useGetProvincesQuery(formData.country_id);
  const { data: genders, error: gendersError, isLoading: isLoadingGenders } = useGetGendersQuery();
  const { data: documentTypes, error: documentTypesError, isLoading: isLoadingDocumentTypes } = useGetDocumentTypesQuery();
  const [country, setCountry] = useState<{ value: number; label: string; }[]>([]);
  const [province, setProvince] = useState<{ value: number; label: string; }[]>([]);
  const [gender, setGender] = useState<{ value: number; label: string; }[]>([]);
  const [documentType, setDocumentType] = useState<{ value: number; label: string; }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const prevRegister = useRef(register);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [visible, setVisible] = useState(false);



  useEffect(() => {
    if (prevRegister.current !== register) {
      if (register.status === 'failed') {
        const error = register.error.response as AsyncThunkPayloadCreatorReturnValue<any, any>;
        setError(error.response.data.message);
        setIsLoading(false);
        setVisible(true);
      }
      if (register.status === 'success') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoading(false);
        setSuccess('Cuenta registrada con éxito.');
        setVisible(true);
        setTimeout(() => {
          dispatch(registerSuccess());
          window.location.href = '/home';
        }, 3000);
      }
      prevRegister.current = register;
    }
    }, [register, dispatch]);


    useEffect(() => {
      if (countries && Array.isArray(countries)) {
        const options = countries.map((country: Country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountry(options);
      }
      else if (countriesError) {
        console.error('Error fetching countries', countriesError);
      }

      if (genders && Array.isArray(genders)) {
        const options = genders.map((gender: Gender) => ({
          value: gender.id,
          label: gender.name,
        }));
        setGender(options);
      }
      else if (gendersError) {
        console.error('Error fetching gender',  gendersError);
      }
      if (documentTypes && Array.isArray(documentTypes)) {
        const options = documentTypes.map((documentType: DocumentType) => ({
          value: documentType.id,
          label: documentType.name,
        }));
        setDocumentType(options);
        const selectedOption = options.find(option => option.value === 1);
        if (selectedOption) {
          setFormData({...formData, document_type_id: selectedOption.value});
        }
      }
      else if (documentTypesError) {
        console.error('Error fetching document Types', documentTypesError);
      }

      if (provinces && Array.isArray(provinces)) {
        const options = provinces.map((province: Province) => ({
          value: province.id,
          label: province.name,
        }));
        setProvince(options);
      }
      else if (provincesError) {
        console.error('Error fetching document Types', provincesError);
      }

    }, [countries, countriesError, documentTypes, documentTypesError, genders, gendersError, provinces, provincesError]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountry = (e: any) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, country_id: selectedValue });
  };

  const handleProvince = (e: any) => {
    setFormData({ ...formData, province_id: e.target.value });
  };

  const handleGender = (e: any) => {
    setFormData({ ...formData, gender_id: e.target.value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const birthday = `${formData.birthday_year}-${formData.birthday_month}-${formData.birthday_day}`;
    setIsLoading(true);

    if (!formData.firstname.trim()) {
      setError('El nombre es requerido');
      setVisible(true);
      setIsLoading(false);
      return;
    }

    if (!formData.lastname.trim()) {
      setError('El apellido es requerido');
      setVisible(true);
      setIsLoading(false);
      return;
    }

    if (!formData.birthday_day || !formData.birthday_month || !formData.birthday_year) {
      setError('La fecha de nacimiento es requerida');
      setVisible(true);
      setIsLoading(false);
      return;
    }

    if (!formData.document.trim()) {
      setError('El DNI es requerido');
      setVisible(true);
      setIsLoading(false);
      return;
    } else if (!/^\d+$/.test(formData.document.trim())) {
      setError('El DNI debe contener solo números');
      setVisible(true);
      setIsLoading(false);
      return;
    }

    if (!formData.email.trim()) {
      setError('El correo electrónico es requerido');
      setVisible(true);
      setIsLoading(false);
      return;
    } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      setError('El correo electrónico no es válido');
      setVisible(true);
      setIsLoading(false);
      return;
    }

    if (!formData.password.trim()) {
      setError('La contraseña es requerida');
      setVisible(true);
      setIsLoading(false);
      return;
    } else if (formData.password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
      setVisible(true);
      setIsLoading(false);
      return;
    }

    if (!formData.password_confirmation.trim()) {
      setError('La confirmación de contraseña es requerida');
      setVisible(true);
      setIsLoading(false);
      return;
    } else if (formData.password !== formData.password_confirmation) {
      setError('Las contraseñas no coinciden');
      setVisible(true);
      setIsLoading(false);
      return;
    }

    const userRegisterData = {
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
      firstname: formData.firstname,
      lastname: formData.lastname,
      birthday: birthday,
      document: formData.document,
      document_type_id: formData.document_type_id,
      country_id: formData.country_id === 0 ? null : formData.country_id,
      province_id: formData.province_id === 0 ? null : formData.province_id,
      gender_id: formData.gender_id === 0 ? null : formData.gender_id,
    };

    try {
      await dispatch(fetchToken());
      await dispatch(userRegister(userRegisterData));
    } catch (error) {
      console.error('Error during registration:', error);
      setError('Error during registration');
      setVisible(true);
      setIsLoading(false);
    }
  };

  return (
    <div id="container" className="not-logged">
      {error && <Alert isVisible={visible} type='error' setVisible={setVisible} text={error} />}
      {success && <Alert isVisible={visible} type='success' setVisible={setVisible} text={success} />}
      {isLoading && <Spinner />}
      <Header type="back-arrow" />
      <div className="main">
        <div className="register-container">
          <form className="register-form">
            <p className="create-text">CREAR CUENTA</p>
            <Input
              name="firstname"
              id="firstname"
              required={true}
              showRequired={true}
              placeholder=""
              onChange={handleChange}
              type="text"
              label="Nombre" />
            <Input
              name="lastname"
              id="lastname"
              required={true}
              showRequired={true}
              placeholder=""
              onChange={handleChange}
              type="text"
              label="Apellido" />
            <Input
                name="email"
                id="email"
                required={true}
                showRequired={true}
                placeholder=""
                type="text"
                onChange={handleChange}
                label="Email" />
            <Input
                name="document"
                id="document"
                required={true}
                showRequired={true}
                placeholder=""
                value={formData['document']}
                type="text"
                label="DNI"
                onChange={handleChange}
                onChangeSelect={handleChange}
                hasSelect={true}
                selectValues={documentType}
                selectValue={1}
                selectName='document_type_id'
                selectId='document_type_id'
            />
            <BirthdayInput
              label="Fecha de nacimiento"
              required={true}
              showRequired={true}
              name="birthday"
              id="birthday"
              onChange={handleChange}
            />
            <Select
                id="gender"
                name="gender"
                label="Género"
                options={gender}
                value={formData['gender_id']}
                onChange={handleGender}/>
            <Select
              id="country"
              name="country"
              label="País"
              options={country}
              value={formData['country_id'] ?? 0}
              onChange={handleCountry}
            />
            <Select
              id="province"
              name="province"
              label="Provincia"
              options={province ?? []}
              value={formData['province_id']}
              onChange={handleProvince}
            />

            <Input
              name="password"
              id="password"
              required={true}
              showRequired={true}
              placeholder=""
              onChange={handleChange}
              type="password"
              info="Mínimo 8 caracteres"
              label="Contraseña" />
            <Input
              name="password_confirmation"
              id="password_confirmation"
              onChange={handleChange}
              required={true}
              showRequired={true}
              placeholder=""
              info="Mínimo 8 caracteres"
              type="password"
              label="Repetir contraseña" />
            <Button type="login" name="CREAR CUENTA" onClick={handleSubmit} />
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );

};

export default SignUp;