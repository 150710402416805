import { userLogout } from '../../redux/features/logoutState';
import {  fetchToken } from "../../redux/features/getToken";
import { useAppDispatch } from "../../redux/store";
import { removeLogin } from "../../redux/features/loginState";
import React from 'react';

const LogoutButton: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleLogout = async () => {
        await dispatch(fetchToken()); 
        await dispatch(userLogout());
        await dispatch(removeLogin({}));
    };

    return (
        <li>
            <a href="#" onClick={handleLogout} className="grey">Cerrar sesión</a>
        </li>
    );
};

export default LogoutButton;