import React, { useState, useEffect } from 'react';
import Logout from 'Components/Logout';
import { ReactComponent as Icon } from 'Resources/Icons/menu-plus.svg';
import './Menu.css';

interface MenuProps {
    isOpen: boolean;
    onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ isOpen = false, onClose }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            const mediaQuery = window.matchMedia('(max-width: 768px)');
            setIsMobile(mediaQuery.matches);
        };

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);
    return (
        <>
            {isOpen && <div className="menu-overlay" onClick={onClose}></div>}
            <div className={`menu-container ${isOpen ? 'open slideRight' : 'slideLeft'}`} style={{ width: isMobile ? '100%' : '35%' }}>
                <ul className={`menu`}>
                    <li><a href="/home" className="green">Home</a></li>
                    <li><a href="/account" className="green">Estado de cuenta</a></li>
                    <li><a href="/profile" className="green">Mi perfil</a></li>
                    <li>
                        <ul className={"menu-options"}>
                            <Logout />
                            {isMobile ? (
                                <li>
                                    <a href="https://www.galponderopa.com/" className="menu-go-to-gdr grey">Volver a galponderopa.com</a>
                                </li>) : null}
                        </ul>
                    </li>
                </ul>
                <div>
                    <button onClick={onClose} className="close-button"><Icon className="close-icon" /></button>
                </div>
            </div>
        </>
    );
};

export default Menu;