import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {Province} from "../../Types/Province";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
});

/**
 * API slice for managing genders data.
 * This includes fetching genders from the backend and persisting the data.
 */
const provincesApi = createApi({
    reducerPath: 'provincesApi',
    baseQuery,
    endpoints: (builder) => ({
        getProvinces: builder.query<Province[], number>({
            query: (countryId) => {
                if (countryId) {
                    return `/provinces/${countryId}`;
                }
                return '';
            },
            transformResponse: (response: { data: Province[] }) => {

                if (response.data) {
                    return response.data.length > 0 ? response.data : [];
                }

                return [];

            }
        }),
    }),
});

// Persist configuration
const provincesPersistConfig = {
    key: 'provincesApi',
    storage,
};

// Apply persistReducer to the API slice reducer
export const persistedProvincesApiReducer = persistReducer(
    provincesPersistConfig,
    provincesApi.reducer
);

// Exports
export const {useGetProvincesQuery} = provincesApi;
export const provincesApiMiddleware = provincesApi.middleware;
