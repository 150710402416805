import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {Country} from "../../Types/Country";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
});

/**
 * API slice for managing countries data.
 * This includes fetching countries from the backend and persisting the data.
 */
const countriesApi = createApi({
    reducerPath: 'countriesApi',
    baseQuery,
    endpoints: (builder) => ({
        getCountries: builder.query<Country[], void>({
            query: () => '/countries/all',
            transformResponse: (response: { data: Country[] }) => {
                return response.data.length > 0 ? response.data : [];
            },

        }),
    }),
});

// Persist configuration
const countriesPersistConfig = {
    key: 'countriesApi',
    storage,
};

// Apply persistReducer to the API slice reducer
export const persistedCountriesApiReducer = persistReducer(
    countriesPersistConfig,
    countriesApi.reducer
);

// Exports
export const { useGetCountriesQuery } = countriesApi;
export const countriesApiMiddleware = countriesApi.middleware;
