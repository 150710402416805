import React from 'react';
import './TextRow.css'
import numeral from 'numeral';

interface TextRowProps {
  credit: number;
  consignation: number;
  cash: number;
}

const TextRow: React.FC<TextRowProps> = ({ credit, consignation, cash }) => {
 
  return (
    <div className="text-row">
      <div className="text-row-container">
        <span>Crédito GDR a favor </span>
        <br/>
        <span className="text-row-value">$ {numeral(credit).format('0,0.00')}</span>
      </div>
      <div className="text-row-container">
        <span>Consignaciones por cobrar </span>
        <br/>
        <span className="text-row-value">$ {numeral(consignation).format('0,0.00')}</span>
      </div>
      <div className="text-row-container">
        <span>Ventas en efectivo </span>
        <br/>
        <span className="text-row-value">$ {numeral(cash).format('0,0.00')}</span>
      </div>
    </div>
  );
};

export default TextRow;