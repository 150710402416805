import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {LoginSlice} from './features/loginState';
import {RegisterSlice} from "./features/registerState";
import {ForgotPasswordSlice} from "./features/forgotPasswordState";
import {ResetPasswordSlice} from "./features/resetPasswordState";
import {emailVerificationNotificationSlice} from "./features/emailVerificationNotificationState";
import {LogoutSlice} from "./features/logoutState";
import { persistedDocumentTypeApiReducer, documentTypeApiMiddleware }  from "./features/getDocumentTypes";
import { persistedCountriesApiReducer, countriesApiMiddleware }  from "./features/getCountries";
import { persistedGendersApiReducer, gendersApiMiddleware } from "./features/getGenders";
import { persistedProvincesApiReducer, provincesApiMiddleware } from "./features/getProvinces";

const loginPersistConfig = {
    key: 'login',
    storage: storage,
};


const persistedLoginReducer = persistReducer(loginPersistConfig, LoginSlice.reducer);

/**
 * Redux store configuration.
 *
 * This function creates a Redux store with the specified reducers.
 *
 * @returns {Store} The configured Redux store.
 */
export const store = configureStore({
    reducer: {
        userLogin: persistedLoginReducer,
        userRegister: RegisterSlice.reducer,
        userForgotPassword: ForgotPasswordSlice.reducer,
        userResetPassword: ResetPasswordSlice.reducer,
        userEmailVerificationNotification: emailVerificationNotificationSlice.reducer,
        userLogout: LogoutSlice.reducer,
        countriesApi: persistedCountriesApiReducer,
        provincesApi: persistedProvincesApiReducer,
        gendersApi: persistedGendersApiReducer,
        documentTypeApi: persistedDocumentTypeApiReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            gendersApiMiddleware,
            provincesApiMiddleware,
            countriesApiMiddleware,
            documentTypeApiMiddleware
        ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;