import React, { useState, useEffect } from 'react';
import axios from '../../redux/features/axios-client';
import numeral from 'numeral';
import Spinner from 'Components/Spinner';
import SortButton from 'Components/SortButton';
import './Table.css';


interface TableProps {
    thead: Array<string>;
}

interface Paginator {
    hasNextPage: boolean;
    totalPages: number;
}


const Table: React.FC<TableProps> = ({ thead }) => {
    const [data, setData] = useState<Array<Record<string, any>>>([]);
    const [paginator, setPaginator] = useState<Paginator>();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setisLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState<Array<number>>([]);
    const [sortOrder, setSortOrder] = useState<'1' | '2' | '3' | '4'>('1');

    useEffect(() => {
        setisLoading(true);
        fetchData();
    }, [currentPage, sortOrder]);


    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sale/list/${sortOrder}/${currentPage}`);
            setData(response.data.data);
            setPaginator(response.data[0]);
            setExpandedRows([]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setisLoading(false);
    };

    const handleSortChange = (order: '1' | '2' | '3' | '4') => {
        setSortOrder(order);
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const toggleRowExpansion = (rowIndex: number) => {
        setExpandedRows(prevExpandedRows => {
            if (prevExpandedRows.includes(rowIndex)) {
                return prevExpandedRows.filter(index => index !== rowIndex);
            } else {
                return [...prevExpandedRows, rowIndex];
            }
        });
    };

    return (
        <div>
            {isLoading && <Spinner />}
            <div className='app-container'>
                <SortButton onSortChange={handleSortChange} />
            </div>
            <table>
                <thead>
                    <tr>
                        {thead.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                        <th style={{width: "15px"}}></th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(data) && data.length > 0 ? (
                        data.map((row, rowIndex) => {
                            const createdAt = row.acquisitionData.created_at.split(' ')[0];
                            const [year, month, day] = createdAt.split('-');
                            const formattedDate = `${day}/${month}/${year}`;

                            return (
                                <React.Fragment key={rowIndex}>
                                    <tr key={rowIndex}>
                                        <td>{formattedDate}</td>
                                        <td>{row.acquisitionData.store_name}</td>
                                        <td>{row.acquisitionData.items_quantity}</td>
                                        <td>{row.acquisitionData.acquisition_type_names}</td>
                                        <td className='expanded-icon-container'>
                                            <span className='expanded-icon' onClick={() => toggleRowExpansion(rowIndex)}>
                                                {expandedRows.includes(rowIndex) ? '-' : '+'}
                                            </span>
                                        </td>
                                    </tr>
                                    {expandedRows.includes(rowIndex) && (
                                        <tr style={{ backgroundColor: '#f4fcf0', fontWeight: '600' }}>
                                            <td>PRENDA</td>
                                            <td>CÓDIGO</td>
                                            <td>TOTAL</td>
                                            <td>ESTADO</td>
                                            <td></td>
                                        </tr>
                                    )}

                                    {expandedRows.includes(rowIndex) && row.productsFromAcquisition.map((product: any, productIndex: any) => (
                                        <tr style={{ backgroundColor: '#f4fcf0' }} key={`${rowIndex}-${productIndex}`}>
                                            <td>{product.brand_category}</td>
                                            <td><code>{product.sku}</code></td>
                                            <td>${numeral(product.product_price).format('0,0.00')}</td>
                                            <td>{product.state_name}</td>
                                            <td></td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={5}>Sin datos para mostrar</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {Array.isArray(data) && data.length > 0 ?
                <div className='paginator'>
                    <span onClick={handlePrevPage} style={{ cursor: 'pointer', textDecoration: 'underline'}}>ANTERIOR</span>
                    <span>Página {currentPage} / {paginator && paginator.totalPages}</span>
                    {paginator && paginator.hasNextPage ?
                        <span onClick={handleNextPage} style={{ cursor: 'pointer', textDecoration: 'underline' }}>SIGUIENTE</span> :
                        <span style={{ cursor: 'not-allowed', textDecoration: 'underline' }}>Siguiente</span>
                    }
                </div> : null
            }
        </div>
    );
};

export default Table;