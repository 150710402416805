import { useParams, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Alert from 'Components/Alert';
import Input from 'Components/Input';
import Footer from 'Components/Footer';
import Header from 'Components/Header';
import Button from 'Components/Button';
import Spinner from 'Components/Spinner'
import { Navigate } from 'react-router-dom';
import axios from '../../redux/features/axios-client';
import {fetchToken} from "../../redux/features/getToken";
import {useAppDispatch} from "../../redux/store";



const Reset: React.FC = () => {
    const dispatch = useAppDispatch();
    let { token } = useParams();
    const location = useLocation();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [toLogin, setToLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handlePasswordConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(event.target.value);
    };

    const sendData = async () => {
        await dispatch(fetchToken());
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reset-password`, {
                token: token,
                email: email,
                password: password,
                password_confirmation: passwordConfirmation,
            });
            if (response.status == 200) {
                setVisible(true);
                setSuccess('Contraseña cambiada con éxito.')
                setTimeout(() => {
                    setToLogin(true);
                }, 3000);
            }
        } catch (error: any) {
            setVisible(true);
            setError(error.response.data.message)
        }
        setIsLoading(false);
    }

    const handleSubmit = async (e: any) => {
        if (!password.trim()) {
            setError('La contraseña es requerida');
            setVisible(true);
            return
        } else if (password.length < 8) {
            setError('La contraseña debe tener al menos 8 caracteres');
            setVisible(true);
            return
        }

        if (!passwordConfirmation.trim()) {
            setError('La confirmación de contraseña es requerida');
            setVisible(true);
            return
        } else if (password !== passwordConfirmation) {
            setError('Las contraseñas no coinciden');
            setVisible(true);
            return
        }
        sendData();
    }

    return (
        <div id="container" className="container not-logged">
            {toLogin && (
                <Navigate to="/home" replace={true} />
            )}
            {isLoading && <Spinner />}
            {error && <Alert isVisible={visible} type='error' setVisible={setVisible} text={error} />}
            {success && <Alert isVisible={visible} type='success' setVisible={setVisible} text={success} />}
            <Header type="back-arrow" />
            <div className="main">
                <div className="register-container">
                    <form className="register-form">
                        <p className="create-text">Ingresa tu nueva contraseña</p>
                        <Input
                            name="password"
                            id="password"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            type="password"
                            info="Mínimo 8 caracteres"
                            onChange={handlePassword}
                            label="Contraseña" />
                        <Input
                            name="password"
                            id="password"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            type="password"
                            info="Mínimo 8 caracteres"
                            onChange={handlePasswordConfirmation}
                            label="Repetir contraseña" />
                        <Button type="login" name="RECUPERAR CONTRASEÑA" onClick={handleSubmit} />
                    </form>
                </div>
            </div>
            <Footer isFixed={true}/>
        </div>
    )
}
export default Reset;