import React from 'react';
import Button from 'Components/Button';
import numeral from 'numeral';

import './Card.css'

interface CardProps {
  text: string;
  amount: number;
  info: string;
  type: string;
}

const Card: React.FC<CardProps> = ({ text, amount, info, type }) => {
  const formattedAmount = numeral(amount).format('0,0.00');

  return (
    <div className={"card"}>
      <div className={"card-title"}>
        <p className="green card-text">{text}</p>
        <p className="green card-text">$ {formattedAmount}</p>
      </div>
      <p className="card-text-info">{info}</p>
      {type === 'credit' ? (
        <Button link={'/movement/credit'} type="login" name="MOVIMIENTOS" />
      ) : type === 'consignation' ? (
        <Button link={'/movement/consignation'} type="login" name="MOVIMIENTOS" />
      ) : null}
      {type === 'consignation' ? (
        <Button link={'/consignation/list'} type="register" name="PRENDAS EN STOCK" />
      ) : null}
    </div>
  );
};

export default Card;