import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from './axios-client';
import {createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {AxiosError} from "axios";
import {ServerResponse} from "../../Types/ServerResponse";
import {ErrorResponse} from "../../Types/ErrorResponse";

const loginPersistConfig = {
    key: 'login',
    storage: storage,
};

interface UserLogin {
    email: string;
    password: string;
}

interface LoginState {
    loginDetails: UserLogin;
    status: string;
    isLogged: boolean;
    server_response: ServerResponse;
    error: ErrorResponse;
}

const initialState: LoginState = {
    loginDetails: {
        email: '',
        password: '',
    },
    status: '',
    isLogged: false,
    server_response: {},
    error: {},
};

/**
 * Async thunk for user login.
 * This handles the asynchronous action of logging in a user by making a POST request to the backend.
 */
export const userLogin = createAsyncThunk(
    "user/login",
    async (user: UserLogin, thunkAPI) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
                email: user.email,
                password: user.password
            });
            return response.data;
        } catch (_err) {
            const error = _err as AxiosError;
            return thunkAPI.rejectWithValue(error);
        }

    },
);

/**
 * Slice for managing user login state.
 * This slice includes the state, reducers, and extra reducers to handle async actions.
 */
export const LoginSlice = createSlice({
    name: "user/login",
    initialState,
    reducers: {
        addLoginData: (state, {payload}) => {
            state.loginDetails.email = payload.email;
            state.loginDetails.password = payload.password;
        },
        removeLogin: (state, {payload}) => {
            state.loginDetails.email = '';
            state.loginDetails.password = '';
            state.isLogged = false;
        },
        registerSuccess: (state) => {
            state.isLogged = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.status = "success";
            state.isLogged = true;
            state.server_response = action.payload;
        });
        builder.addCase(userLogin.rejected, (state, action) => {
            state.status = "failed";
            state.error.response = action.payload;
        });
        builder.addCase(userLogin.pending, (state) => {
            state.status = "loading";
        });
    },
});


const persistedLoginReducer = persistReducer(loginPersistConfig, LoginSlice.reducer);

export const {addLoginData, removeLogin, registerSuccess} = LoginSlice.actions;
export default persistedLoginReducer;