import React from 'react';
import Input from 'Components/Input';
import './BirthdayInput.css';

interface BirthdayInputProps {
    label: string;
    required: boolean;
    showRequired: boolean;
    name: string;
    id: string;
    value_day?: string;
    value_month?: string;
    value_year?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const BirthdayInput: React.FC<BirthdayInputProps> = ({
                                                         label,
                                                         required,
                                                         showRequired,
                                                         name,
                                                         value_day,
                                                         value_month,
                                                         value_year,
                                                         id,
                                                         onChange
                                                     }) => {
    const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        if (/^\d+$/.test(value) && Number(value) <= 31) {
            if (onChange) {
                onChange(e);
            }
        } else {
            e.target.value = '';
        }
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        if (/^\d+$/.test(value) && Number(value) <= 12) {
            if (onChange) {
                onChange(e);
            }
        } else {
            e.target.value = '';
        }
    };
    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        if (/^\d+$/.test(value) && value.length <= 4) {
            if (onChange) {
                onChange(e);
            }
        } else {
            e.target.value = '';
        }
    };

    return (
        <div className="container-input container-birthday-input">
            <label className='label-input'> {label}
                { showRequired && <span style={{color: 'red'}}> *</span>}</label>
            <div className="container-birthday-group">
                <Input
                    name={`${name}_day`}
                    id={`${id}_day`}
                    required={required}
                    showRequired={false}
                    placeholder="dd"
                    onChange={handleDayChange}
                    type="number"
                    value={value_day}
                />
                <Input
                    name={`${name}_month`}
                    id={`${id}_month`}
                    required={required}
                    showRequired={false}
                    placeholder="mm"
                    onChange={handleMonthChange}
                    type="number"
                    value={value_month}
                />
                <Input
                    name={`${name}_year`}
                    id={`${id}_year`}
                    required={required}
                    showRequired={false}
                    placeholder="aaaa"
                    onChange={handleYearChange}
                    type="number"
                    value={value_year}
                />
            </div>
        </div>
    );
};

export default BirthdayInput;