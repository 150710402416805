import React, {useEffect, useRef, useState} from 'react';
import Button from 'Components/Button';
import Header from 'Components/Header';
import Input from 'Components/Input';
import Footer from 'Components/Footer';
import Spinner from 'Components/Spinner'
import {Navigate} from 'react-router-dom';
import {fetchToken} from "../../redux/features/getToken";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import Alert from 'Components/Alert';
import {userForgotPassword} from "../../redux/features/forgotPasswordState";
import {AsyncThunkPayloadCreatorReturnValue} from "@reduxjs/toolkit";


const Forgot: React.FC = () => {
    const dispatch = useAppDispatch();
    const userForgot = useAppSelector((state) => state.userForgotPassword);
    const prevUserForgot = useRef(userForgot);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [toLogin, setToLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false);

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const fetchData = async () => {
        setIsLoading(true);
        await dispatch(fetchToken());
        await dispatch(userForgotPassword({email}));
    };


    useEffect(() => {
        if (prevUserForgot.current !== userForgot) {
            if (userForgot.status === 'failed') {
                const error = userForgot.error.response as AsyncThunkPayloadCreatorReturnValue<any, any>;
                setError(error.response.data.message);
                setIsLoading(false);
                setVisible(true);
            }
            setIsLoading(false);
            if (userForgot.status === 'success') {
                const response = userForgot.server_response as AsyncThunkPayloadCreatorReturnValue<any, any>;
                setIsLoading(false);
                setVisible(true);
                setSuccess(response.status);
                setTimeout(() => {
                    setToLogin(true);
                }, 4000);
            }
            prevUserForgot.current = userForgot;
        }
    }, [userForgot]);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (e: any) => {
        if (!email) {
            setError('Por favor, complete ingrese el mail primero.');
            setVisible(true);
            return;
        }
        if (!isValidEmail(email)) {
            setError('Por favor, ingrese un correo electrónico válido.');
            setVisible(true);
            return;
        }
        fetchData();

    }

    return (
        <div className='container not-logged'>
            {toLogin && (
                <Navigate to="/home" replace={true}/>
            )}
            {isLoading && <Spinner/>}
            {error && <Alert isVisible={visible} type='error' setVisible={setVisible} text={error}/>}
            {success && <Alert isVisible={visible} type='success' setVisible={setVisible} text={success} />}
            <Header type="back-arrow"/>
            <div className="main">
                <div className="register-container">
                    <form className="register-form">
                    <p className="create-text">OBTENER / RECUPERAR MI CONTRASEÑA</p>
                        <Input
                            name="email"
                            id="email"
                            className={"input--w-100"}
                            required={true}
                            showRequired={true}
                            placeholder=""
                            type="text"
                            onChange={handleEmailChange}
                            label="Ingresá tu correo eléctronico"/>
                        <Button type="login" name="RECUPERAR CONTRASEÑA" onClick={handleSubmit}/>
                    </form>
                </div>
            </div>
            <Footer isFixed={true}/>
        </div>
    );
};

export default Forgot;