import React, {ChangeEvent} from 'react';
import './Input.css';

interface InputProps {
    label?: string,
    required: boolean,
    showRequired: boolean,
    name: string,
    id: string,
    className?: string,
    type: string,
    value?: string,
    info?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    placeholder: string,
    hasSelect?: boolean,
    selectValues?: { value: number; label: string }[],
    selectValue?: number,
    selectName?: string,
    selectId?: string
}

const InputComponent: React.FC<InputProps> = ({
                                                  label,
                                                  required,
                                                  showRequired,
                                                  name,
                                                  type,
                                                  id,
                                                  selectName,
                                                  selectId,
                                                  className,
                                                  info,
                                                  placeholder,
                                                  onChange,
                                                  onChangeSelect,
                                                  onKeyDown,
                                                  value,
                                                  hasSelect,
                                                  selectValues,
                                                  selectValue
                                              }) => {


    return (
        <div className="container-input">
            <label className="label-input">
                {label}
                {showRequired && <span style={{color: 'red'}}> *</span>}
            </label>
            <div className="input-wrapper">
                {hasSelect && selectValues && (
                    <select className="select-input" name={selectName} id={selectId} onChange={onChangeSelect} value={selectValue}>
                        {selectValues.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                )}
                <input
                    type={type}
                    className={`input ${className} ${required ? 'required' : ''}`}
                    required={required}
                    name={name}
                    id={id}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
            </div>
            <label className="label-input">{info}</label>
        </div>
    );
};

export default InputComponent;