import React,{useState, useEffect} from 'react';
import './Alert.css'
import { ReactComponent as Icon } from 'Resources/Icons/menu-plus.svg';

interface AlertProps {
  text: string;
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  type?: 'error' | 'success';
}

const Alert: React.FC<AlertProps> = ({ text, isVisible, setVisible, type }) => {
  
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [isVisible, type, setVisible]);

  const handleCloseClick = () => {
    setVisible(false);
  };
  
  return (
    <div className={`alert ${isVisible ? '' : 'hidden'} ${type === 'error' ? 'alert-error' : 'alert-info'}`}>
      <div className="close-btn" onClick={handleCloseClick}><Icon className="close-icon" /></div>
      <div className="alert-text" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default Alert;