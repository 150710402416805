import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from './axios-client';

/**
 * Thunk action creator to fetch the CSRF token from the backend.
 *
 * This function sends a request to the backend API to fetch the CSRF token
 * for cross-site request forgery (CSRF) protection.
 *
 * @param {ThunkAPI} thunkAPI - The Redux thunk API object.
 * @returns {Promise<any>} A promise that resolves with the CSRF token data from the backend.
 */
export const fetchToken = createAsyncThunk(
    "token/fetch",
    async (thunkAPI) => {

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sanctum/csrf-cookie`);
        return response.data;

    });




