import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from './axios-client';
import {AxiosError} from "axios";
import {ServerResponse} from "../../Types/ServerResponse";
import {ErrorResponse} from "../../Types/ErrorResponse";

interface UserResetPassword {
    email: string;
    token: string;
    password: string;

}

interface ResetPasswordState {
    resetPasswordDetails: UserResetPassword;
    status: string;
    server_response: ServerResponse;
    error: ErrorResponse;
}

const initialState: ResetPasswordState = {
    resetPasswordDetails: {
        email: "",
        token: "",
        password: "",
    },
    status: '',
    server_response: {},
    error: {},
};

/**
 * Thunk action creator to handle user password reset.
 *
 * This function sends a request to the backend API to reset the user's password.
 *
 * @param {UserResetPassword} user - The user object containing the password reset details.
 * @param {ThunkAPI} thunkAPI - The Redux thunk API object.
 * @returns {Promise<any>} A promise that resolves with the response data from the backend.
 */
export const userResetPassword = createAsyncThunk(
    "user/reset-password",
    async (user: UserResetPassword, thunkAPI) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reset-password`, {
                email: user.email,
                token: user.token,
                password: user.password
            });
            return response.data;
        } catch (_err) {
            const error = _err as AxiosError;
            return thunkAPI.rejectWithValue(error);
        }

    },
);

/**
 * Slice to manage the state related to user password reset.
 *
 * This slice manages the state for user password reset details, status, and server response.
 */
export const ResetPasswordSlice = createSlice({
    name: "user/reset-password",
    initialState,
    reducers: {
        addResetPasswordData: (state, {payload}) => {
            state.resetPasswordDetails.email = payload.email;
            state.resetPasswordDetails.token = payload.token;
            state.resetPasswordDetails.password = payload.password;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userResetPassword.fulfilled, (state, action) => {
            state.status = "success";
            state.server_response = action.payload;
        });
        builder.addCase(userResetPassword.rejected, (state, action) => {
            state.status = "failed";
            state.error.response = action.payload;
        });
        builder.addCase(userResetPassword.pending, (state) => {
            state.status = "loading";
        });
    },
});


export const {addResetPasswordData} = ResetPasswordSlice.actions;
export default ResetPasswordSlice.reducer;