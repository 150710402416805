import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from './axios-client';
import {AxiosError} from "axios";
import {ServerResponse} from "../../Types/ServerResponse";
import {ErrorResponse} from "../../Types/ErrorResponse";

interface LogoutState {
    status: string,
    server_response: ServerResponse,
    error: ErrorResponse
}

const initialState: LogoutState = {
    status: '',
    server_response: {},
    error: {}
};

/**
 * Thunk action creator to handle user logout.
 *
 * This function sends a request to the backend API to log out the user.
 *
 * @param {ThunkAPI} thunkAPI - The Redux thunk API object.
 * @returns {Promise<any>} A promise that resolves with the response data from the backend.
 */
export const userLogout = createAsyncThunk(
    "user/logout",
    async (thunkAPI) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logout`);
            return response.data;
        } catch (_err) {
            return _err as AxiosError;
        }
    },
);

/**
 * Slice to manage the state related to user logout.
 *
 * This slice manages the state for user logout status and server response.
 */
export const LogoutSlice = createSlice({
    name: "user/logout",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userLogout.fulfilled, (state, action) => {
            state.status = "success";
            state.server_response = action.payload;
        });
        builder.addCase(userLogout.rejected, (state, action) => {
            state.status = "failed";
            state.error.response = action.payload;
        });
        builder.addCase(userLogout.pending, (state) => {
            state.status = "loading";

        });
    },
});


export default LogoutSlice.reducer;