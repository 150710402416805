import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {DocumentType} from "../../Types/DocumentType";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
});

/**
 * API slice for managing document types data.
 * This includes fetching document types from the backend and persisting the data.
 */
export const documentTypeApi = createApi({
    reducerPath: 'documentTypeApi',
    baseQuery,
    endpoints: (builder) => ({
        getDocumentTypes: builder.query<DocumentType[], void>({
            query: () => '/document-type/all',
            transformResponse: (response: { data: DocumentType[] }) => {
                return response.data.length > 0 ? response.data : [];
            },
        }),
    }),
});

// Persist configuration
const documentTypePersistConfig = {
    key: 'documentTypeApi',
    storage,
};

// Apply persistReducer to the API slice reducer
export const persistedDocumentTypeApiReducer = persistReducer(
    documentTypePersistConfig,
    documentTypeApi.reducer
);

// Exports
export const { useGetDocumentTypesQuery } = documentTypeApi;
export const documentTypeApiMiddleware = documentTypeApi.middleware;

