import React, { useEffect, useRef, useState } from 'react';
import './Carousel.css';
import axios from '../../redux/features/axios-client';

interface CarouselProps {
  quantity: number;
}

const Carousel: React.FC<CarouselProps> = ({ quantity }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [totalRecirculatedProducts, setTotalRecirculatedProducts] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer/total_recirculated_products`);
        setTotalRecirculatedProducts(response.data.totalRecirculatedProducts);
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    };
    fetchData();
    const container = containerRef.current;
    if (!container) return;

    const scroll = () => {
      if (!container) return;
      container.scrollTo({
        left: container.scrollLeft + 1,
        behavior: 'smooth',
      });
    };

    const interval = setInterval(scroll, 1500);

    return () => clearInterval(interval);
  }, []);
  const formatNumber = (number: number): string => {
    const formattedNumber = new Intl.NumberFormat('es-AR').format(number);
    return formattedNumber;
  };

  return (
    <div className="text-carousel-container" ref={containerRef}>
      <div className="text-carousel">
        <span>Recirculaste {formatNumber(totalRecirculatedProducts || 0)} prendas</span>
      </div>
    </div>
  );
};

export default Carousel;