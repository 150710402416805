import Axios from 'axios';

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
})

export default axios