import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {Gender} from "../../Types/Gender";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
});

/**
 * API slice for managing genders data.
 * This includes fetching genders from the backend and persisting the data.
 */
const gendersApi = createApi({
    reducerPath: 'gendersApi',
    baseQuery,
    endpoints: (builder) => ({
        getGenders: builder.query<Gender[], void>({
            query: () => '/genders/all',
            transformResponse: (response: { data: Gender[] }) => {
                return response.data.length > 0 ? response.data : [];
            },
        }),
    }),
});

// Persist configuration
const gendersPersistConfig = {
    key: 'gendersApi',
    storage,
};

// Apply persistReducer to the API slice reducer
export const persistedGendersApiReducer = persistReducer(
    gendersPersistConfig,
    gendersApi.reducer
);

// Exports
export const { useGetGendersQuery } = gendersApi;
export const gendersApiMiddleware = gendersApi.middleware;