import React, { useState, useEffect } from 'react';
import Button from 'Components/Button';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Spinner from 'Components/Spinner';
import Alert from 'Components/Alert';
import Input from 'Components/Input';
import Select from 'Components/Select';
import BirthdayInput from 'Components/BirthdayInput';
import axios from '../../redux/features/axios-client';
import './Profile.css';
import { useGetCountriesQuery} from '../../redux/features/getCountries';
import { useGetProvincesQuery} from '../../redux/features/getProvinces';
import { useGetGendersQuery} from '../../redux/features/getGenders';
import { useGetDocumentTypesQuery} from '../../redux/features/getDocumentTypes';
import {Country} from "../../Types/Country";
import {Gender} from "../../Types/Gender";
import {Navigate} from 'react-router-dom';
import {Province} from "../../Types/Province";
import {DocumentType} from "../../Types/DocumentType";

const Profile: React.FC = () => {

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        birthday: '',
        birthday_day: '',
        birthday_month: '',
        birthday_year: '',
        phone: '',
        zip_code: '',
        document: '',
        document_type: 0,
        country_id: 0,
        gender_id: 0,
        province_id: 0,
        email: '',
        password: '',
        password_confirmation: ''
    });

    const { data: countries, error: countriesError, isLoading: isLoadingCountries } = useGetCountriesQuery();
    const { data: provinces, error: provincesError, isLoading: isLoadingProvinces } =useGetProvincesQuery(formData.country_id);
    const { data: genders, error: gendersError, isLoading: isLoadingGenders } = useGetGendersQuery();
    const { data: documentTypes, error: documentTypesError, isLoading: isLoadingDocumentTypes } = useGetDocumentTypesQuery();
    const [country, setCountry] = useState<{ value: number; label: string; }[]>([]);
    const [province, setProvince] = useState<{ value: number; label: string; }[]>([]);
    const [gender, setGender] = useState<{ value: number; label: string; }[]>([]);
    const [documentType, setDocumentType] = useState<{ value: number; label: string; }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toHome, setToHome] = useState(false);


    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [visible, setVisible] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(e.target.value, 10);
        setFormData({ ...formData, country_id: selectedValue });
    };

    const handleProvince = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(e.target.value, 10);
        setFormData({ ...formData, province_id: selectedValue });
    };

    const handleGender = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(e.target.value, 10);
        setFormData({ ...formData, gender_id: selectedValue });
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer/data`);
                const customerData = response.data.customerData;

                let year: string | null = null;
                let month: string | null = null;
                let day: string | null = null;

                if (customerData.birthday) {
                    [year, month, day] = customerData.birthday.split('-');
                }
                setFormData({
                    ...customerData,
                    birthday_day: day,
                    birthday_month: month,
                    birthday_year: year,
                    document_type: customerData.document_type,
                    gender_id: customerData.gender_id,
                    country_id: customerData.country_id,
                    province_id: customerData.province_id
                });
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching customer data:', error);
                setIsLoading(false);
            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (countries && Array.isArray(countries)) {
            const options = countries.map((country: Country) => ({
                value: country.id,
                label: country.name,
            }));
            setCountry(options);
        }
        else if (countriesError) {
            console.error('Error fetching countries', countriesError);
        }

        if (genders && Array.isArray(genders)) {
            const options = genders.map((gender: Gender) => ({
                value: gender.id,
                label: gender.name,
            }));
           setGender(options);
            }
        else if (gendersError) {
            console.error('Error fetching gender',  gendersError);
        }
        if (documentTypes && Array.isArray(documentTypes)) {
            const options = documentTypes.map((documentType: DocumentType) => ({
                value: documentType.id,
                label: documentType.name,
            }));
            setDocumentType(options);
        }
        else if (documentTypesError) {
            console.error('Error fetching document Types', documentTypesError);
        }

        if (provinces && Array.isArray(provinces)) {
            const options = provinces.map((province: Province) => ({
                value: province.id,
                label: province.name,
            }));
            setProvince(options);
        }
        else if (provincesError) {
            console.error('Error fetching document Types', provincesError);
        }

    }, [countries, countriesError, documentTypes, documentTypesError, genders, gendersError, provinces, provincesError]);



    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!formData.firstname.trim()) {
            setError('El nombre es requerido');
            setVisible(true);
            setIsLoading(false);
            return;
        }

        if (!formData.lastname.trim()) {
            setError('El apellido es requerido');
            setVisible(true);
            setIsLoading(false);
            return;
        }

        if (!formData.birthday_day || !formData.birthday_month || !formData.birthday_year) {
            setError('La fecha de nacimiento es requerida');
            setVisible(true);
            setIsLoading(false);
            return;
        }

        if (!formData.document.trim()) {
            setError('El DNI es requerido');
            setVisible(true);
            setIsLoading(false);
            return;
        } else if (!/^\d+$/.test(formData.document.trim())) {
            setError('El DNI debe contener solo números');
            setVisible(true);
            setIsLoading(false);
            return;
        }

        if (!formData.email.trim()) {
            setError('El correo electrónico es requerido');
            setVisible(true);
            setIsLoading(false);
            return;
        } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
            setError('El correo electrónico no es válido');
            setVisible(true);
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/edit`, {
                email: formData.email,
                firstname: formData.firstname,
                lastname: formData.lastname,
                address: '',
                zip_code: formData.zip_code,
                phone: formData.phone,
                birthday: `${formData.birthday_year}-${formData.birthday_month}-${formData.birthday_day}`,
                document: formData.document,
                document_type_id: formData.document_type,
                gender_id: formData.gender_id,
                province_id: formData.province_id,
                country_id: formData.country_id
            });

            if (response.status === 200) {
                setVisible(true);
                setSuccess(response.data.message);
                setIsLoading(false);
                setTimeout(() => {
                    setToHome(true);
                }, 2000);
            }
        } catch (error: any) {
            setVisible(true);
            setError(error.response.data.message);
            setIsLoading(false);
        }
    };

    return (
        <div id="container">
            {toHome && (
                <Navigate to="/home" replace={true}/>
            )}
            {error && <Alert isVisible={visible} type='error' setVisible={setVisible} text={error} />}
            {success && <Alert isVisible={visible} type='success' setVisible={setVisible} text={success} />}
            {isLoading && <Spinner />}
            <Header type="menu" />
            <div className="main">
                <div className="register-container">
                    <form className="register-form" >
                        <p className="create-text">MI PERFIL</p>
                        <Input
                            name="firstname"
                            id="firstname"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            value={formData['firstname']}
                            onChange={handleChange}
                            type="text"
                            label="Nombre"
                            hasSelect={false} />
                        <Input
                            name="lastname"
                            id="lastname"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            value={formData['lastname']}
                            onChange={handleChange}
                            type="text"
                            label="Apellido"
                            hasSelect={false} />
                        <Input
                            name="email"
                            id="email"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            value={formData['email']}
                            type="email"
                            onChange={handleChange}
                            label="Email"
                            hasSelect={false}
                        />
                        <Input
                            name="document"
                            id="document"
                            required={true}
                            showRequired={true}
                            placeholder=""
                            value={formData['document']}
                            type="text"
                            label="DNI"
                            onChange={handleChange}
                            onChangeSelect={handleChange}
                            hasSelect={true}
                            selectValues={documentType}
                            selectValue={formData['document_type']}
                            selectName='document_type'
                            selectId='document_type'
                        />
                        <BirthdayInput
                            label="Fecha de nacimiento"
                            required={true}
                            showRequired={true}
                            name="birthday"
                            id="birthday"
                            value_day={formData['birthday_day']}
                            value_month={formData['birthday_month']}
                            value_year={formData['birthday_year']}
                            onChange={handleChange}
                        />
                        <Select
                            id="gender"
                            name="gender"
                            label="Género"
                            options={gender}
                            value={formData['gender_id']}
                            onChange={handleGender}/>
                        <Input
                            name="phone"
                            id="phone"
                            required={false}
                            showRequired={false}
                            placeholder="Numero de telefono"
                            value={formData['phone']}
                            onChange={handleChange}
                            type="phone"
                            label="Teléfono"
                            hasSelect={false}/>
                        <Select
                            id="country"
                            name="country"
                            label="País"
                            options={country}
                            value={formData['country_id']}
                            onChange={handleCountry}
                            required
                        />
                        <Select
                            id="province"
                            name="province"
                            label="Provincia"
                            options={province ?? []}
                            value={formData['province_id']}
                            onChange={handleProvince}
                            required
                        />
                        <Input
                            name="zip_code"
                            id="zip_code"
                            required={false}
                            showRequired={false}
                            placeholder="Codigo postal"
                            value={formData['zip_code']}
                            onChange={handleChange}
                            type="text"
                            label="Codigo Postal"
                            hasSelect={false}/>
                        <Button type="login" onClick={handleSubmit} name="EDITAR" />
                        <Button link={'/edit/password'} type="register" name="CAMBIAR CONTRASEÑA" />
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;