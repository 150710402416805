import React, { useState, useEffect } from 'react';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import TableType from 'Components/TableType';
import TextRow from 'Components/TextRow';
import Spinner from 'Components/Spinner';
import axios from '../../../redux/features/axios-client';
import Button from "Components/Button";


interface CustomerData {
    current_debt: number;
    current_credit: number;
    current_cash: number;
}

interface AdquisitionsCashData {
    first_acquisition_date: string;
    total_cost: number;
}

const Credit: React.FC = () => {
    const [customerData, setCustomerData] = useState<CustomerData | null>(null);
    const [adquisitionsCashData, setAdquisitionsCashData] = useState<AdquisitionsCashData | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer/data`);
                setCustomerData(response.data.customerData);
                setAdquisitionsCashData(response.data.acquisitionsCashData);   
            } catch (error) {
                console.error('Error fetching customer data:', error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);


    return (
        <div className="container" id="container">
            {isLoading && <Spinner />}
            <Header type="menu" />
            <div className="main">
                <TextRow credit={customerData?.current_credit || 0}
                consignation={customerData?.current_debt || 0}
                cash={adquisitionsCashData?.total_cost || 0} />
                <p className="movement-text">Crédito /<strong> Movimientos</strong></p>
                <TableType
                    type={'credit'}
                    thead={['Fecha', 'Operación', 'Local', 'Total']}
                />
                <div className='container-button'>
                    <Button name="VOLVER A ESTADO DE CUENTA" link={'/account'} type="login" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Credit;