import React, { useState, useEffect } from 'react';
import './Header.css';
import Menu from 'Components/Menu';
import { ReactComponent as Logo } from 'Resources/Images/logo-gdr.svg';
import { ReactComponent as Icon } from 'Resources/Icons/menu-plus.svg';

interface HeaderProps {
    type: string;
}

const Header: React.FC<HeaderProps> = (type) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            const mediaQuery = window.matchMedia('(max-width: 768px)');
            setIsMobile(mediaQuery.matches);
        };

        checkIsMobile();

        const handleScroll = () => {
            const scrolled = window.scrollY > 0;
            setIsScrolled(scrolled);
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);

        window.addEventListener('resize', checkIsMobile);
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleCloseMenu = () => {
        setIsOpen(false);
    };

    const goBack = () => {
        const currentPath = window.location.pathname;

        if (currentPath === '/not-found' || window.history.length <= 1) {
            window.location.href = '/';
        } else {
            window.history.back();
        }
    };

    return (

        <header className={`header ${isScrolled ? 'header--fixed' : ''}`}>
            <div className="header-container">
            {isMobile ? (
                <div className="center-section">
                    <Logo className="logo" />
                </div>
            ) : (
                (type.type == 'menu' || type.type === 'back-arrow' ? (
                    <div className="left-section">
                        <div onClick={type.type === 'menu' ? toggleMenu : goBack}
                            style={{ fontSize: type.type !== 'menu' ? '2em' : 'inherit', cursor: 'pointer' }}
                            className="left-section">
                            {type.type === 'menu' ? 'Menú' : '←'}
                        </div>
                    </div>
                ) : <div className="left-section"></div>)
            )}
            {isMobile && type.type === 'menu' ? (
                <div className="right-section">
                    <div onClick={toggleMenu}><Icon className="open-icon"/></div>
                </div>
            ) : (
                !isMobile && (
                    <div className="center-section">
                        <a href="/" className="back-link">
                            <Logo className="logo" />
                        </a>
                    </div>
                )
            )}
            {!isMobile && (
                <div className="right-section">
                    <a href="https://www.galponderopa.com" className="back-link">Volver a galponderopa.com</a>
                </div>
            )}
            </div>
            <Menu isOpen={isOpen} onClose={handleCloseMenu} />

        </header>

    );
};

export default Header;