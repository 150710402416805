import React, { useState } from 'react';
import Header from 'Components/Header';
import Footer from 'Components/Footer';

const Forgot: React.FC = () => {

  return (
    <div id="container">
      <Header type="back-arrow" />
      <p style={{textAlign:'center'}}>Página no existente</p>
      <Footer />
    </div>
  );
};

export default Forgot;