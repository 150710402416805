import React from 'react';
import './Select.css';


interface SelectProps {
  id: string;
  name: string;
  label: string;
  options: { value: number; label: string }[];
  value: number | string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
}

const Select: React.FC<SelectProps> = ({ id, name, label, options, value, onChange, required }) => {
  return (
      <div className="container-select">
        <label htmlFor={id} className="label-input">{label}</label>
        <select id={id} name={name} value={value} onChange={onChange} required={required} className="input--w-100">
          <option value="">{`Seleccionar ${label.toLowerCase()}`}</option>
          {Array.isArray(options) && options.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
  );
};

export default Select;