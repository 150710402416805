import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from './axios-client';

/**
 * Thunk action creator to send an email notification for email verification.
 *
 * This function sends a request to the backend API to send an email notification
 * for email verification.
 *
 * @returns {Promise<any>} A promise that resolves with the response data from the backend.
 */
export const userVerifyEmailNotification = createAsyncThunk(
    "user/send-email-notification",
    async (thunkAPI) => {

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/email/verification-notification`);
        return response.data;
    },
);

/**
 * Slice to manage the state related to email verification notifications.
 *
 * This slice manages the state for sending email verification notifications,
 * including the status of the notification and the server response.
 */
export const emailVerificationNotificationSlice = createSlice({
    name: "/email/verification-notification",
    initialState: {
        status: '',
        server_response: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userVerifyEmailNotification.fulfilled, (state, action) => {
            state.status = "success";
            state.server_response = action.payload;
        });
        builder.addCase(userVerifyEmailNotification.rejected, (state, action) => {
            state.status = "failed";
            state.server_response = action;
        });
        builder.addCase(userVerifyEmailNotification.pending, (state) => {
            state.status = "loading";
        });
    },
});

export default emailVerificationNotificationSlice.reducer;