import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from 'Pages/Login';
import SignUp from 'Pages/SignUp';
import Home from 'Pages/Home';
import Forgot from 'Pages/Forgot';
import Profile from 'Pages/Profile';
import Account from 'Pages/Account';
import Stock from 'Pages/Stock';
import Credit from 'Pages/Movement/Credit';
import Consignation from 'Pages/Movement/Consignation';
import NotFound from 'Pages/NotFound';
import Reset from 'Pages/Reset';
import Password from 'Pages/Password';
import './App.css';
import { useAppSelector } from "./redux/store";
import '@fontsource/uncut-sans';

const App: React.FC = () => {

    const userlogin = useAppSelector((state) => state.userLogin);

    if (userlogin.isLogged) {
        return (
            <Router>
                <Routes>
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/movement/credit" element={<Credit />} />
                    <Route path="/movement/consignation" element={<Consignation />} />
                    <Route path="/consignation/list" element={<Stock />} />
                    <Route path="/edit/password" element={<Password />} />
                    <Route path="*" element={<Navigate to="/not-found" />} />
                </Routes>
            </Router>
        );
    }
    else {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/register" element={<SignUp />} />
                    <Route path="/forgot" element={<Forgot />} />
                    <Route path="/password-reset/:token" element={<Reset />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>)
    }
};

export default App;