import React, { useState, useEffect } from 'react';
import './Footer.css';
import { ReactComponent as Instagram } from 'Resources/Icons/Instagram.svg';
import { ReactComponent as Tiktok } from 'Resources/Icons/Tiktok.svg';
import { ReactComponent as Linkedin } from 'Resources/Icons/Linkedin.svg';
import { ReactComponent as Whatsapp } from 'Resources/Icons/Whatsapp.svg';

interface FooterProps {
    isFixed?: boolean;
}

const Footer: React.FC<FooterProps> = () => {

        return (
            <footer className='footer'>
                <div className="footer-container">
                    <div>
                        <div className='footer-text'>Seguinos</div>
                        <div className="footer-images-container">
                            <a href="https://ar.linkedin.com/company/galponderopa">
                                <Linkedin className="icon" />
                            </a>
                            <a href="https://www.instagram.com/galponderopa">
                                <Instagram className="icon" />
                            </a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B541168926201&text&type=phone_number&app_absent=0">
                                <Whatsapp className="icon" />
                            </a>
                            <a href="https://www.tiktok.com/@galponderopa">
                                <Tiktok className="icon" />
                            </a>
                        </div>
                    </div>
                    <div className="footer-images-container">
                        <img className="icon-footer" src={require('Resources/Images/Footer-B-certified.png')} alt="" />
                        <img className="icon-footer" src={require('Resources/Images/Footer-great-place-to-work.png')} alt="" />
                    </div>
                </div>
            </footer>
        );
    }

export default Footer;