import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from './axios-client';
import {AxiosError} from "axios";
import {ServerResponse} from "../../Types/ServerResponse";
import {ErrorResponse} from "../../Types/ErrorResponse";

interface UserRegister {
    email: string;
    password: string;
    password_confirmation: string;
    document_type_id: number;
    firstname: string;
    lastname: string;
    birthday: string;
    document: string;
    country_id: number | null;
    province_id: number | null;
    gender_id: number | null;
}


interface RegisterState {
    registerDetails: UserRegister;
    status: string;
    server_response: ServerResponse;
    error: ErrorResponse;
}

const initialState: RegisterState = {
    registerDetails: {
        email: "",
        password: "",
        password_confirmation: "",
        firstname: "",
        lastname: "",
        birthday: "",
        document: "",
        document_type_id: 0,
        country_id: 0,
        province_id: 0,
        gender_id: 0,

    },
    status: '',
    server_response: {},
    error: {}
};

/**
 * Thunk action creator to handle user registration.
 *
 * This function sends a request to the backend API to register a new user.
 *
 * @param {UserRegister} user - The user object containing the registration details.
 * @param {ThunkAPI} thunkAPI - The Redux thunk API object.
 * @returns {Promise<any>} A promise that resolves with the response data from the backend.
 */
export const userRegister = createAsyncThunk(
    "user/register",
    async (user: UserRegister, thunkAPI) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, {
                email: user.email,
                password: user.password,
                firstname: user.firstname,
                lastname: user.lastname,
                birthday: user.birthday,
                document: user.document,
                country_id: user.country_id,
                province_id: user.province_id,
                document_type_id: user.document_type_id,
                gender_id: user.gender_id,
                password_confirmation: user.password_confirmation
            });
            return response.data;
        } catch (_err) {
            const error = _err as AxiosError;
            return thunkAPI.rejectWithValue(error);
        }
    },
);

/**
 * Slice to manage the state related to user registration.
 *
 * This slice manages the state for user registration details, status, and server response.
 */
export const RegisterSlice = createSlice({
    name: "user/register",
    initialState,
    reducers: {
        addRegisterData: (state, {payload}) => {
            state.registerDetails.email = payload.email;
            state.registerDetails.password = payload.password;
            state.registerDetails.password_confirmation = payload.password_confirmation;
            state.registerDetails.firstname = payload.firstname;
            state.registerDetails.lastname = payload.lastname;
            state.registerDetails.document = payload.document;
            state.registerDetails.birthday = payload.birthday;
            state.registerDetails.country_id = payload.country_id;
            state.registerDetails.province_id = payload.province_id;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(userRegister.fulfilled, (state, action) => {
            state.status = "success";
            state.server_response = action.payload;
        });
        builder.addCase(userRegister.rejected, (state, action) => {
            state.status = "failed";
            state.error.response = action.payload;
        });
        builder.addCase(userRegister.pending, (state) => {
            state.status = "loading";
        });
    },
});


export const {addRegisterData} = RegisterSlice.actions;
export default RegisterSlice.reducer;
