import React, { useState, useEffect } from 'react';
import './SortButton.css';

interface SortButtonProps {
  onSortChange: (order: '1' | '2' | '3' | '4') => void;
}

const SortButton: React.FC<SortButtonProps> = ({ onSortChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<'1' | '2' | '3' | '4'>('1');

  useEffect(() => {
    onSortChange(selectedOrder);
  }, [selectedOrder, onSortChange]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSort = (order: '1' | '2' | '3' | '4') => {
    setSelectedOrder(order);
    setIsOpen(false);
    console.log(order)
  };

  const getButtonLabel = () => {
    switch (selectedOrder) {
      case '1':
        return 'ORDENAR POR MAYOR FECHA';
      case '2':
        return 'ORDENAR POR MENOR FECHA';
      case '3':
        return 'ORDENAR POR MAYOR VALOR';
      case '4':
        return 'ORDENAR POR MENOR VALOR';
      default:
        return 'ORDENAR POR';
    }
  };

  return (
    <div className="sort-button">
      <button className="button" onClick={toggleMenu}>
        {getButtonLabel()}
        {/*<span className="arrow">{isOpen ? '↓' : '↓'}</span>*/}
        <span className="arrow">↓</span>
      </button>
      {isOpen && (
        <div className="menu-sort">
          <div className="menu-item" onClick={() => handleSort('1')}>
                ORDENAR POR MAYOR FECHA
          </div>
          <div className="menu-item" onClick={() => handleSort('2')}>
                ORDENAR POR MENOR FECHA
          </div>
          <div className="menu-item" onClick={() => handleSort('3')}>
                ORDENAR POR MAYOR VALOR
          </div>
          <div className="menu-item" onClick={() => handleSort('4')}>
                ORDENAR POR MENOR VALOR
          </div>
        </div>
      )}
    </div>
  );
};

export default SortButton;