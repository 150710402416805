import React, { useState, useEffect } from 'react';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Card from 'Components/Card';
import Table from 'Components/Table';
import axios from '../../redux/features/axios-client';
import './Account.css';

interface CustomerData {
    current_debt: number;
    current_credit: number;
    current_cash: number;
    adquisitionsCashData: AdquisitionsCashData;
}

interface AdquisitionsCashData {
    first_acquisition_date: string;
    total_cost: number;
}

const Account: React.FC = () => {
    const [customerData, setCustomerData] = useState<CustomerData | null>(null);
    const [adquisitionsCashData, setAdquisitionsCashData] = useState<AdquisitionsCashData | null>(null);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer/data`);
                setCustomerData(response.data.customerData);  
                setAdquisitionsCashData(response.data.acquisitionsCashData)
            } catch (error) {
                console.error('Error fetching customer data:', error);
            }
        };
        fetchData();

    }, []);
    const formattedDate = adquisitionsCashData?.first_acquisition_date ? new Date(adquisitionsCashData.first_acquisition_date).toLocaleDateString('es-AR') : '';
    
    return (
        <div className="container">
            <Header type="menu" />
            <div className="main">
                <p className="account-text">ESTADO DE CUENTA</p>
                <div className="account-card-container">
                    <Card text={'Crédito GDR'} type={'credit'} amount={customerData?.current_credit || 0} info={'* Para usar en nuestros locales, sin vencimiento'}/>
                    <Card text={'Consignación'} type={'consignation'} amount={customerData?.current_debt || 0} info={'* Disponible para cobrar en nuestros locales'}/>
                    <Card text={'Efectivo'} type={'cash'} amount={adquisitionsCashData?.total_cost || 0} info={`* Total cobrado desde ${formattedDate}`} />
                </div>
                <p className="account-text">RESUMEN DE VENTAS</p>
                <Table
                    thead={['FECHA', 'LOCAL', 'TOTAL', 'SISTEMA DE COBRO']}
                />
            </div>
            <Footer/>
        </div>
    );
};

export default Account;