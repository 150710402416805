import React, { useState, useEffect } from 'react';
import axios from '../../redux/features/axios-client';
import numeral from 'numeral';
import SortButton from 'Components/SortButton';
import Spinner from 'Components/Spinner';

interface TableProps {
    thead: Array<string>;
    type: string;
}

interface Product {
    ticket_types_name: string,
    store_name: string,
    created_at: string,
    amount: number;
}

interface ProductStock {
    brand_category: string,
    created_at: string;
    store_name: string;
    product_price: number;
}

interface Paginator {
    hasNextPage: boolean;
    totalPages: number;
}

const TableType: React.FC<TableProps> = ({ thead, type }) => {
    const [data, setData] = useState<Product[]>([]);
    const [productStock, setProductStock] = useState<ProductStock[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginator, setPaginator] = useState<Paginator>();
    const [isLoading, setIsLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState<'1' | '2' | '3' | '4'>('1');

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [currentPage, sortOrder]);

    const fetchData = async () => {
        try {
            if (type == 'credit') {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/credit/list/${sortOrder}/${currentPage}`);
                setData(response.data.data);
                setPaginator(response.data.pagination);
            }
            else if (type == 'consignation') {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consignment/list/${sortOrder}/${currentPage}`);
                setData(response.data.data);
                setPaginator(response.data.pagination);
            }
            else {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/consignment/list/${sortOrder}/${currentPage}`);
                setProductStock(response.data.data);
                setPaginator(response.data.pagination);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handleSortChange = (order: '1' | '2' | '3' | '4') => {
        setSortOrder(order);
    };

    const shouldShowPagination = type === 'stock' ? productStock.length > 0 : data.length > 0;

    if (type == 'stock') {
        return (
            <div>
                {isLoading && <Spinner />}
                <div className='app-container'>
                    <SortButton onSortChange={handleSortChange} />
                </div>
                <table>
                    <thead>
                        <tr>
                            {thead.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(productStock) && productStock.length > 0 ? (
                            productStock.map((row, rowIndex) => {
                                const formattedDate = row.created_at ? new Date(row.created_at).toLocaleDateString('es-AR') : '';

                                return (
                                    <React.Fragment key={rowIndex}>
                                        <tr key={rowIndex}>
                                            <td>{formattedDate}</td>
                                            <td>{row.brand_category}</td>
                                            <td>{row.store_name}</td>
                                            <td>${numeral(row.product_price).format('0,0.00')}</td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={5}>Sin datos para mostrar</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className='paginator'>
                    <span onClick={handlePrevPage} style={{ cursor: 'pointer', textDecoration: 'underline' }}>ANTERIOR</span>
                    <span>Página {currentPage} / {paginator && paginator.totalPages}</span>
                    {paginator && paginator.hasNextPage ?
                        <span onClick={handleNextPage} style={{ cursor: 'pointer', textDecoration: 'underline' }}>SIGUIENTE</span> :
                        <span style={{ cursor: 'not-allowed', textDecoration: 'underline' }}>Siguiente</span>
                    }
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                {isLoading && <Spinner />}
                <div className='app-container'>
                    <SortButton onSortChange={handleSortChange} />
                </div>
                <table>
                    <thead>
                        <tr>
                            {thead.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(data) && data.length > 0 ? (
                            data.map((row, rowIndex) => {
                                const createdAt = row.created_at.split(' ')[0];
                                const [year, month, day] = createdAt.split('-');
                                const formattedDate = `${day}/${month}/${year}`;

                                return (
                                    <React.Fragment key={rowIndex}>
                                        <tr key={rowIndex}>
                                            <td>{formattedDate}</td>
                                            <td>{row.ticket_types_name}</td>
                                            <td>{row.store_name}</td>
                                            <td>${numeral(row.amount).format('0,0.00')}</td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={5}>Sin datos para mostrar</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {shouldShowPagination && (
                    <div className='paginator'>
                        <span
                            onClick={handlePrevPage}
                            style={{ cursor: currentPage > 1 ? 'pointer' : 'not-allowed', textDecoration: 'underline' }}
                        >
                            ANTERIOR
                        </span>
                        <span>Página {currentPage} / {paginator?.totalPages}</span>
                        {paginator?.hasNextPage ? (
                            <span
                                onClick={handleNextPage}
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                                SIGUIENTE
                            </span>
                        ) : null}
                    </div>
                )}
            </div>
        );
    }
};

export default TableType;