import React, { useState, useEffect, useRef } from 'react';
import './Login.css';
import Button from 'Components/Button';
import Header from 'Components/Header';
import { Navigate  } from 'react-router-dom';
import Input from 'Components/Input';
import Footer from 'Components/Footer';
import Alert from 'Components/Alert';
import Spinner from 'Components/Spinner';
import { fetchToken } from "../../redux/features/getToken";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { userLogin } from "../../redux/features/loginState";
import {AsyncThunkPayloadCreatorReturnValue} from "@reduxjs/toolkit";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const userlogin = useAppSelector((state) => state.userLogin);
  const prevUserLogin = useRef(userlogin);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState(false);
  const [error, setError] = useState('');
  const [visible, setVisible] = useState(false);


  useEffect(() => {
    if (prevUserLogin.current !== userlogin) {
      if (userlogin.status === 'failed') {
        const error = userlogin.error.response as AsyncThunkPayloadCreatorReturnValue<any, any>;
        setError(error.response.data.message);
        setIsLoading(false);
        setVisible(true);
      } 
      if (userlogin.status === 'success') {
        setLogin(true)
      }
      prevUserLogin.current = userlogin;
    }
  }, [userlogin]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  };

  const isValidEmail = (email:string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLoginClick = async() => {
    if (!email || !password) {
      setError('Por favor, completá ambos campos.');
      setVisible(true);
      return;
    }

    if (!isValidEmail(email)) {
      setError('Por favor, ingresá un correo electrónico válido.');
      setVisible(true);
      return;
    }

    if (password.length < 8) {
      setError('Contraseña no valida.');
      setVisible(true);
      return
    }

    setIsLoading(true);
    await dispatch(fetchToken());
    await dispatch(userLogin({email,password}));
  };

  return (
    <div className="container not-logged index-page">
      {login && (
          <Navigate to="/home" replace={true} />
      )}
      {isLoading && <Spinner />}
      {error && <Alert isVisible={visible} type='error' setVisible={setVisible} text={error} />}
      <Header type="login" />
      <picture>
        <source media="(min-width: 900px)" srcSet={require('Resources/Images/banner-desktop-20240823.png')} />
        <source media="(max-width: 899px)" srcSet={require('Resources/Images/banner-mobile-20240823.png')} />
        <img src={require('Resources/Images/banner-mobile-20240823.png')} alt="" className="banner" />
      </picture>
      <div className="main">

        <div className="login-container">
          <form className="login-form">
            <div className="form-group">
              <Input
                type="email"
                id="email"
                name="email"
                className="input--w-100"
                label=""
                onChange={handleEmailChange}
                placeholder="E-mail"
                required={true}
                showRequired={false}
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                id="password"
                name="email"
                className="input--w-100"
                label=""
                onChange={handlePasswordChange}
                onKeyDown={handleKeyDown}
                placeholder="Contraseña"
                required={true}
                showRequired={false}
              />
            </div>
            <Button name="INICIAR SESIÓN" type="login" onClick={handleLoginClick} />
            <a href="/register" className="no-decoration"><Button type="create" name="CREAR CUENTA" /></a>
          </form>
        </div>
        <div className="container-pass">
          <a href="/forgot" className="pass"><u>Obtener / Recuperar mi contraseña</u></a>
        </div>
      </div>
      <Footer isFixed={true} />
    </div>
  );
};

export default Login;