import React, { useState, useEffect } from 'react';
import Button from 'Components/Button';
import Header from 'Components/Header';
import Carousel from 'Components/Carousel';
import Banner from 'Components/Banner';
import Footer from 'Components/Footer';
import TextRow from 'Components/TextRow';
import axios from '../../redux/features/axios-client';
import { removeLogin } from "../../redux/features/loginState";
import { useAppDispatch } from "../../redux/store";
import Spinner from 'Components/Spinner';
import './Home.css'


interface CustomerData {
    current_debt: number;
    current_credit: number;
    current_cash: number;
}

interface AdquisitionsCashData {
    first_acquisition_date: string;
    total_cost: number;
}

const Home: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [customerData, setCustomerData] = useState<CustomerData | null>(null);
    const [adquisitionsCashData, setAdquisitionsCashData] = useState<AdquisitionsCashData | null>(null);

    // Adjust the state path as per your store setup
    //const status = useSelector((state: any) => state.countries.status);


    useEffect(() => {


        setIsLoading(true);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer/data`);
                setCustomerData(response.data.customerData);   
                setAdquisitionsCashData(response.data.acquisitionsCashData)
            } catch (error: any) {
                if (error.response.status == 401) {
                    await dispatch(removeLogin({}));
                }
                console.error('Error fetching customer data:', error);
            }
            setIsLoading(false);
        };

        fetchData();

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className="container" id="container">
            {isLoading && <Spinner />}
            <Header type="menu" />
            <div className="main">
                <TextRow credit={customerData?.current_credit || 0}
                         consignation={customerData?.current_debt || 0}
                         cash={adquisitionsCashData?.total_cost || 0}/>
            </div>
            <Carousel quantity={0} />
            <div className="main main-home">
                <div className='container-home'>
                    <p>Gracias por ser parte del futuro circular.<br/> En este panel podrás acceder al estado<br/> de tu cuenta para conocer las ventas.</p>
                </div>
                <div className='container-button'>
                    <Button name="VER ESTADO DE CUENTA" link={'/account'} type="login" />
                </div>
            </div>
            <Banner 
            title="Qué priorizamos ahora"
            description="Estamos priorizando abrigos livianos, remeras, vestidos, pantalones, jeans, shorts y polleras que acompañen las tendencias de la primavera. Recordá que la presentación y el estado de tu prendas son determinantes al momento de la selección."
            extend="Vamos a priorizar talles grandes principalmente en pantalones y jeans."
            link="https://galponderopa.geco.com.ar/turno/nuevo"
            />
            <Footer/>
        </div>
    );
};

export default Home;